import {Config, GlobalConfig} from "../../types";
import {defaultInitialTargetValue} from "../../const";

export type CrewCompositionItem = string | { value: string, label: string};

export const configInitialValues: Config = {
  crew: "",
  crewComposition: [],
  takeOffLocation: '',
  attackArea: '',
  frequencies: '',
  altitude: 'до 150м',
  areaOfResponsibility: '',
  initialTargetValue: defaultInitialTargetValue,
  stream: '',
};

export const globalConfigInitialValues: GlobalConfig = {
  defaultFlightTime: 0,
  shouldHaveStartRequest: false,
  shouldHaveStreamField: false,
  shouldHaveInitiatorField: false,
  shouldHaveEmptyResultInRequest: false,
  shouldCountUsedVehicles: false,
  goal: '',
  crewComposition: {},
  vehicleTypes: [],
  frequencies: [],
  ammo: [],
  detonators: [],
  initiators: [],
  ammoComposition: {},
  objectives: {},
  objectiveOptions: [],
  results: [],
  checklistItems: [],
}