import React, {useContext, useState} from 'react';
import Header from "../components/Header";
import Wrapper from "../components/Wrapper";
import {Button, Divider, IconButton, styled, Typography} from "@mui/material";
import {ViewType} from "../const";
import SettingsIcon from '@mui/icons-material/Settings';
import AppContext from "../AppContext";
import CreateReportForm from "../components/CreateReportForm";
import {Report} from "../types";
import ReportCard from "../components/ReportCard";
import {formatReport, formatRequest} from "../utils/reportUtils";
import Modal from "@mui/material/Modal";
import ConfirmModalBody from "../components/ConfirmModalBody";
import { useSnackbar } from "notistack";
import ChecklistIcon from "@mui/icons-material/Checklist";
import AssignmentIcon from '@mui/icons-material/Assignment';
import UsedAmmo from "../components/UsedAmmo";
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import UsedVehicles from "../components/UsedVehicles";

const ReportView = () => {
  const { updateView, reports, updateReports, config, updateConfig, globalConfig } = useContext(AppContext);
  const {enqueueSnackbar} = useSnackbar();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleCloseModal = () => setOpenDeleteModal(false);

  const handleCreateReport = (report: Report) => {
    updateReports([...reports, report]);
    const formattedRequestText = formatRequest(report, config, globalConfig);
    navigator.clipboard.writeText(formattedRequestText);
    enqueueSnackbar('Заявка скопійована до буферу обміну', { variant: "success" });
  }

  const handleCopyAll = () => {
    navigator.clipboard.writeText('Звіт\n\n'
      + 'Підрозділ: 2 ЗвЗг 411 ОП БпС\n'
      + `Екіпаж: Зведений екіпаж ${config.crew}\n`
      + `СКЛАД: ${config.crewComposition.join(', ')}\n`
      + `Кількість вильотів : ${reports.length}\n\n`
      + reports.map((report, i) => formatReport(i+1, report, config, globalConfig)).join('\n\n'));

    enqueueSnackbar('Звіт скопійовано до буферу обміну', { variant: "success" });
  };

  const handleClearReports = () => {
    updateReports([]);
    updateConfig(null);
    setOpenDeleteModal(false);
  };

  return <>
    <Header>
      <Typography sx={{ flexGrow: 1}} variant="h5">Звіти</Typography>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        sx={{ ml: 2 }}
        onClick={() => updateView(ViewType.Checklist)}
      >
        <ChecklistIcon />
      </IconButton>
      {Boolean(globalConfig.shouldHaveStartRequest) && <IconButton
          size="large"
          edge="start"
          color="inherit"
          sx={{ ml: 2 }}
          onClick={() => updateView(ViewType.startRequest)}
      >
        <AssignmentIcon />
      </IconButton>}
      <IconButton
        size="large"
        color="inherit"
        sx={{ ml: 2 }}
        onClick={() => updateView(ViewType.GlobalConfig)}
      >
        <DriveFolderUploadIcon />
      </IconButton>
      <IconButton
        size="large"
        color="inherit"
        sx={{ ml: 2, mr: -2 }}
        onClick={() => updateView(ViewType.Config)}
      >
        <SettingsIcon />
      </IconButton>
    </Header>
    <StyledWrapper>
      <CreateReportForm onSubmit={handleCreateReport}/>
      <StyledDivider />
      <Button
        disabled={reports.length === 0}
        sx={{ mb: 4 }}
        variant="contained"
        onClick={handleCopyAll}
      >Скопіювати звіт</Button>
      <Button
        disabled={reports.length === 0}
        onClick={() => setOpenDeleteModal(true)}
      >Очистити список</Button>
      <StyledDivider />
      <div>
        {reports.map((report, i) => <React.Fragment key={report.id}>
          <ReportCard report={report} reportNumber={i} />
          {i !== reports.length - 1 && <StyledDivider />}
        </React.Fragment>)}
      </div>
      <StyledDivider />
      <UsedAmmo />
      {globalConfig.shouldCountUsedVehicles && <>
        <StyledDivider />
        <UsedVehicles />
    </>}
    </StyledWrapper>
    <Modal open={openDeleteModal} onClose={handleCloseModal}>
      <ConfirmModalBody
        title="Ви впевнені що хочете очистити список вильотів?"
        confirmActionHandler={handleClearReports}
        declineActionHandler={handleCloseModal}
      />
    </Modal>
  </>;
};

export default ReportView;

const StyledWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
`;
const StyledDivider = styled(Divider)`
  margin: ${({ theme }) => theme.spacing(4)} 0;
`;