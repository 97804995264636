import React, {createContext, useEffect, useState} from "react";
import {Config, GlobalConfig, GlobalConfigInit, Report, StartRequest, TargetMapType} from "./types";
import {
  configKey,
  defaultInitialTargetValue, globalConfigKey,
  reportsKey,
  startRequestConfigInitialValues, startRequestConfigKey,
  targetMapKey,
  ViewType
} from "./const";
import {configInitialValues, globalConfigInitialValues} from "./components/ConfigForm/const";
import {updateAtIndex} from "./utils/arrayUtils";
import {isGlobalConfigValid} from "./utils/isGlobalConfigValid";

type AppContextType = {
  startRequestConfig: StartRequest,
  updateStartRequestConfig: (startRequestConfig: StartRequest) => void;
  config: Config,
  updateConfig: (config: Config | null) => void,
  reports: Report[],
  updateReports: (reports: Report[]) => void,
  view: ViewType,
  updateView: (view: ViewType) => void,
  targetMap: TargetMapType,
  updateTargetMap: (newTargets: TargetMapType) => void,
  removeTargetFromMap: (key: string) => void,
  checklistState: boolean[];
  updateChecklistItem: (value: boolean, i: number) => void;
  resetChecklist: () => void;
  globalConfig: GlobalConfig;
  updateGlobalConfig: (config: GlobalConfigInit) => void;
}

const initValues = {
  startRequestConfig: startRequestConfigInitialValues,
  updateStartRequestConfig: () => {},
  config: configInitialValues,
  updateConfig: () => {},
  reports: [],
  updateReports: () => {},
  view: ViewType.Reports,
  updateView: () => {},
  targetMap: {},
  updateTargetMap: () => {},
  removeTargetFromMap: () => {},
  checklistState: [],
  updateChecklistItem: () => {},
  resetChecklist: () => {},
  globalConfig: globalConfigInitialValues,
  updateGlobalConfig: () => {},
}

const AppContext = createContext<AppContextType>(initValues);

// @ts-ignore
export const AppProvider = ({ children }) => {
  const [globalConfig, setGlobalConfig] = useState<GlobalConfig>(globalConfigInitialValues);
  const [startRequestConfig, setStartRequestConfig] = useState<StartRequest>(startRequestConfigInitialValues);
  const [config, setConfig] = useState<Config>(configInitialValues);
  const [reports, setReports] = useState<Report[]>([]);
  const [view, setView] = useState<ViewType>(ViewType.Reports);
  const [targetMap, setTargetMap] = useState<TargetMapType>({});
  const [checklistState, setChecklistState] = useState<boolean[]>([]);

  const updateConfig = (config: Config | null) => {
    setConfig(prevState => {
      const newConfig = config || {
        ...prevState,
        crew: configInitialValues.crew,
        crewComposition: configInitialValues.crewComposition,
      };
      localStorage.setItem(configKey, JSON.stringify(newConfig));
      return newConfig;
    });
    if(!config) {
      setView(ViewType.Config);
    }
  };

  const updateStartRequestConfig = (startRequestConfig: StartRequest) => {
    setStartRequestConfig(startRequestConfig);
    localStorage.setItem(startRequestConfigKey, JSON.stringify(startRequestConfig));
  }

  const updateReports = (reports: Report[]) => {
    setReports(reports);
    localStorage.setItem(reportsKey, JSON.stringify({ reports: reports}));
  }

  const updateView = (view: ViewType) => setView(view);

  const updateTargetMap = (targetMap: TargetMapType) => {
    setTargetMap(prevState => {
      const newMap = Object.assign(prevState, targetMap);
      localStorage.setItem(targetMapKey, JSON.stringify(newMap));
      return ({ ...newMap});
    });
  };

  const removeTargetFromMap = (key: string) => {
    setTargetMap(prevState => {
      const newMap = Object.assign(prevState, {});
      delete newMap[key];
      localStorage.setItem(targetMapKey, JSON.stringify(newMap));
      return ({...newMap});
    })
  };

  const resetAfterGlobalConfigUpdate = (newGlobalConfig: GlobalConfigInit) => {
    setChecklistState((new Array(newGlobalConfig.checklistItems.length)).fill(false));
    updateReports([]);
    updateConfig(null);
    updateStartRequestConfig(startRequestConfigInitialValues);
  }

  const updateGlobalConfig = (newGlobalConfig: GlobalConfigInit) => {
    resetAfterGlobalConfigUpdate(newGlobalConfig);
    const globalConfig = ({
      ...newGlobalConfig,
      objectiveOptions: Object.keys(newGlobalConfig.objectives),
    })
    setGlobalConfig(globalConfig);
    localStorage.setItem(globalConfigKey, JSON.stringify(globalConfig));
  };

  //init
  useEffect(() => {
    const serializedGlobalConfig = localStorage.getItem(globalConfigKey);
    if(serializedGlobalConfig){
      try {
        const savedGlobalConfig = JSON.parse(serializedGlobalConfig);
        if(savedGlobalConfig && isGlobalConfigValid(savedGlobalConfig)){
          setGlobalConfig(savedGlobalConfig);
          setChecklistState((new Array(savedGlobalConfig.checklistItems.length)).fill(false));
        }else{
          updateView(ViewType.GlobalConfig);
        }
      }catch(e){
        localStorage.removeItem(globalConfigKey);
      }
    }else{
      updateView(ViewType.GlobalConfig);
    }

    const serializedConfig = localStorage.getItem(configKey);
    if(serializedConfig){
      try {
        const savedConfig = JSON.parse(serializedConfig);
        if(savedConfig){
          setConfig({
            ...savedConfig,
            initialTargetValue: savedConfig.initialTargetValue || defaultInitialTargetValue,
          });
        }
        if(!savedConfig?.crewComposition?.length){
          updateView(ViewType.Config);
        }
      }catch(e){
        localStorage.removeItem(configKey);
      }
    }else{
      updateView(ViewType.Config);
    }

    const serializedStartRequestConfig = localStorage.getItem(startRequestConfigKey);
    if(serializedStartRequestConfig){
      try {
        const savedStartRequestConfig = JSON.parse(serializedStartRequestConfig);
        if(savedStartRequestConfig){
          setStartRequestConfig({
            ...savedStartRequestConfig,
            startDate: new Date(savedStartRequestConfig?.startDate),
            endDate: new Date(savedStartRequestConfig?.endDate),
          })
        }
      }catch (e) {
        localStorage.removeItem(startRequestConfigKey);
      }
    }

    const reportsSerialized = localStorage.getItem(reportsKey)
    if(reportsSerialized){
      try{
        const reportsObj = JSON.parse(reportsSerialized);
        setReports(reportsObj?.reports?.map((report: Report) => ({
          ...report,
          startTime: new Date(report?.startTime),
          results: typeof report?.results?.[0] === 'string' ? report.results.map(result => ({ result: result, description: ''})) : report.results
        })) || []);
      }catch(e){
        localStorage.removeItem(reportsKey);
      }
    }

    const serializedTargetMap = localStorage.getItem(targetMapKey);
    if(serializedTargetMap){
      try {
        const targetMap = JSON.parse(serializedTargetMap);
        setTargetMap(targetMap || {})
      }catch (e) {
        localStorage.removeItem(targetMapKey)
      }
    }
  }, []);

  const updateChecklistItem = (value: boolean, i: number) => {
    setChecklistState(prevState => updateAtIndex(prevState, i, value));
  };

  const resetChecklist = () => {
    setChecklistState(prevState => prevState.map(() => false));
  }

  return <AppContext.Provider value={{
    startRequestConfig,
    updateStartRequestConfig,
    config,
    reports,
    updateConfig,
    updateReports,
    view,
    updateView,
    targetMap,
    updateTargetMap,
    removeTargetFromMap,
    checklistState,
    updateChecklistItem,
    resetChecklist,
    globalConfig,
    updateGlobalConfig,
  }}>{children}</AppContext.Provider>
};

export default AppContext;