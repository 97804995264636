import {Config, GlobalConfig, Report, ResultInto, StartRequest, Target} from "../types";
import {format} from "date-fns";

export const formatRequest = (report: Report, config: Config, globalConfig: GlobalConfig) => {
  const {
    startTime,
    hitTime,
    objective,
    targets,
    ammos,
    vehicleType,
    frequencies,
    stream,
  } = report;
  const {
    crew,
    altitude,
    attackArea,
    takeOffLocation,
    areaOfResponsibility,
  } = config;
  const {
    shouldHaveStreamField,
    shouldHaveInitiatorField,
    shouldHaveEmptyResultInRequest,
    goal,
  } = globalConfig;
  return `Екіпаж ${crew} 411 ОП БпС, який виконує завдання щодо ${goal} в смузі відповідальності ${areaOfResponsibility}
Засіб: ${vehicleType}
Час: ${format(startTime, 'HH:mm')}
Взліт: ${takeOffLocation}
Район ураження: ${attackArea}
Частоти: ${frequencies}
Висота: ${altitude}
Мета: ${objective}
${formatTargets(targets)}${shouldHaveStreamField ? ('\nВежа: ' + stream || '-') : ''}
Детонатор: ${joinLabelByComma(ammos.map(ammo => `${ammo.detonator}${ammo.detonatorQuantity && ammo.detonatorQuantity > 1 ? ` ${ammo.detonatorQuantity} шт.` : ''}`))}
${shouldHaveInitiatorField ? 'Ініціатор' : 'Плата'}: ${joinLabelByComma(ammos.map(ammo => `${ammo.initiator}${ammo.initiatorQuantity && ammo.initiatorQuantity > 1 ? ` ${ammo.initiatorQuantity} шт.` : ''}`))}
БК: ${joinLabelByComma(ammos.map(ammo => `${ammo.ammoType}${ammo.ammoQuantity && ammo.ammoQuantity > 1 ? ` ${ammo.ammoQuantity} шт.` : ''}`))}${shouldHaveEmptyResultInRequest ? '\nРезультат: -' : ''}
Час ураження: ${format(hitTime, 'HH:mm')}`
};

export const formatReport = (flightNumber: number, report: Report, config: Config, globalConfig: GlobalConfig) => {
  const {
    startTime,
    hitTime,
    objective,
    targets,
    ammos,
    results,
    frequencies,
    vehicleType,
    stream,
  } = report;

  const {
    altitude,
    attackArea,
  } = config;

  const {
    shouldHaveStreamField,
    shouldHaveInitiatorField,
  } = globalConfig
  return `№: ${flightNumber}
Дата, час: ${format(startTime, 'dd.MM.yyyy, HH:mm')}
Район ураження: ${attackArea}
Засіб: ${vehicleType}
Частоти: ${frequencies}
Висота: ${altitude}
Мета: ${objective.trim()}
${formatTargets(targets)}${shouldHaveStreamField ? ('\nВежа: ' + stream || '-') : ''}
Детонатор: ${joinLabelByComma(ammos.map(ammo => `${ammo.detonator}${ammo.detonatorQuantity && ammo.detonatorQuantity > 1 ? ` ${ammo.detonatorQuantity} шт.` : ''}`))}
${shouldHaveInitiatorField ? 'Ініціатор' : 'Плата'}: ${joinLabelByComma(ammos.map(ammo => `${ammo.initiator}${ammo.initiatorQuantity && ammo.initiatorQuantity > 1 ? ` ${ammo.initiatorQuantity} шт.` : ''}`))}
БК: ${joinLabelByComma(ammos.map(ammo => `${ammo.ammoType}${ammo.ammoQuantity && ammo.ammoQuantity > 1 ? ` ${ammo.ammoQuantity} шт.` : ''}`))}
${formatResults(results)}
Час ураження: ${format(hitTime, 'HH:mm')}`;
};

const formatTargets = (targets: Target[]) => targets.reduce((acc, target, i) => acc + `Ціль${targets.length > 1 ? ' '+(i+1) : ''}: ${target?.description ? target.description + ', ' : ''}${target.value}${i !== targets.length -1 ? '\n' : ''}`, '');
const formatResults = (results: ResultInto[]) => results?.length === 1 ? `Результат: ${results[0]?.result}${results[0]?.description && ', ' + results[0].description}` :
  results.reduce((acc, result, i) => acc + `Результат ${i+1}: ${result.result}${result?.description && ', ' + result.description}${i !== results.length -1 ? '\n' : ''}`, '');

const joinLabelByComma = (arr: string[]) => arr.join(', ') || '-';

export const formatStartRequest = (config: Config, startRequestConfig: StartRequest) => `З ${format(startRequestConfig.startDate || new Date(), 'HH:mm dd.MM.yyyy')} по ${format(startRequestConfig.endDate || new Date(), 'HH:mm dd.MM.yy')},  Підрозділ: 2 ЗвЗг 411 ОП БпС
2. Позивний: Зведений екіпаж ${config.crew}: склад групи: ${config.crewComposition.join(', ')}
3. Тип БпЛА і серійний №: ${startRequestConfig.vehicleTypes.map(item => item.vehicleType + ' №' + item.serialNumber).join(', ')}
4. Час роботи: ${format(startRequestConfig.startDate || new Date(), 'HH:mm')} - ${format(startRequestConfig.endDate || new Date(), 'HH:mm')}
5. Точка злету: ${config.takeOffLocation}
6. Висота: ${startRequestConfig.altitude}
7. Частота: ${config.frequencies}
8. Р-н виконання завдань: Зона відповідальності ${config.areaOfResponsibility}, ${config.attackArea}
9. БК:  ${startRequestConfig.ammo.join(', ')}
10: Детонатор: ${startRequestConfig.detonators.length ? startRequestConfig.detonators.join(', ') : '-'}
11: Ініціатор: ${startRequestConfig.initiators.length ? startRequestConfig.initiators.join(', ') : '-'}
12. Контактний  телефон: ${startRequestConfig.contactPhone}
13. Стрім в Вежі: ${startRequestConfig.streamName}`;