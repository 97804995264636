import React, {useContext, useMemo} from 'react';
import AppContext from "../AppContext";
import {IconButton, styled, Typography} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

type AmmoQuantity = {
  [x: string]: number;
}

const UsedAmmo = () => {

  const { reports } = useContext(AppContext);

  const usedAmmo = useMemo(() => {
    if(reports){
      return reports.reduce((obj: AmmoQuantity, report) => {
        report.ammos.forEach(ammo => {
          if(obj[ammo.ammoType]){
            obj[ammo.ammoType] += ammo.ammoQuantity || 1;
          }else{
            obj[ammo.ammoType] = ammo.ammoQuantity || 1;
          }
        });

        return obj;
      }, {} as AmmoQuantity)
    }else{
      return {};
    }
  }, [reports]);

  const handleCopyUsedAmmo = () => {
    navigator.clipboard.writeText('Використане БК: \n'
    + Object.entries(usedAmmo).map(usedAmmoItem => `${usedAmmoItem[0]}: ${usedAmmoItem[1]}`).join('\n'));
  }

  return <Wrapper>
    <StyledIconButton
      size="large"
      color="inherit"
      onClick={() => handleCopyUsedAmmo()}
    >
      <ContentCopyIcon />
    </StyledIconButton>
    <Typography variant="h5">
      Використане БК:</Typography>
    {Object.entries(usedAmmo).map(usedAmmoItem => <Typography key={usedAmmoItem[0]}>{usedAmmoItem[0]}: {usedAmmoItem[1]}</Typography>)}
  </Wrapper>
};

export default UsedAmmo;

const Wrapper = styled('div')`
  position: relative;
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`;
