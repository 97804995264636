import React, {useContext, useMemo} from 'react';
import AppContext from "../AppContext";
import {IconButton, styled, Typography} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

type VehicleQuantity = {
  [x: string]: number;
}

const UsedVehicles = () => {
  const { reports } = useContext(AppContext);

  const usedVehicles = useMemo(() => {
    if(reports){
      return reports.reduce((obj: VehicleQuantity, report) => {
        if(obj[report.vehicleType]){
          obj[report.vehicleType]++;
        }else{
          obj[report.vehicleType] = 1;
        }

        return obj;
      }, {} as VehicleQuantity)
    }else{
      return {};
    }
  }, [reports]);


  const handleCopyUsedVehicles = () => {
    navigator.clipboard.writeText('Використані засоби: \n'
      + Object.entries(usedVehicles).map(usedVehicleItem => `${usedVehicleItem[0]}: ${usedVehicleItem[1]}`).join('\n'));
  };

  return <Wrapper>
    <StyledIconButton
      size="large"
      color="inherit"
      onClick={() => handleCopyUsedVehicles()}
    >
      <ContentCopyIcon />
    </StyledIconButton>
    <Typography variant="h5">
      Використані засоби:</Typography>
    {Object.entries(usedVehicles).map(usedVehicleItem => <Typography key={usedVehicleItem[0]}>{usedVehicleItem[0]}: {usedVehicleItem[1]}</Typography>)}
  </Wrapper>
}

export default UsedVehicles;

const Wrapper = styled('div')`
  position: relative;
`;

const StyledIconButton = styled(IconButton)`
  position: absolute;
  top: 0;
  right: 0;
`;