import React, {useContext, useEffect, useState} from 'react';
import {Autocomplete, Button, styled, TextField} from "@mui/material";
import InputMask from 'react-input-mask';
import * as yup from 'yup';
import {v4 as uuidv4} from 'uuid';
import {
  defaultInitialTargetValue,
  ErrorMessages, hhmmRexExp,
  initialAmmoValue,
  mgrsCoordinatesRegExp,
} from "../const";
import {useFormik} from "formik";
import {addDays, addMinutes, format, isFuture, parse} from "date-fns";
import TargetInput from "./TargetInput";
import {removeAtIndex} from "../utils/arrayUtils";
import {AmmoForm, Report} from "../types";
import AmmoInput from "./AmmoInput";
import convertTargetsToTargetMap from "../utils/convertTargetsToTargetMap";
import AppContext from "../AppContext";

type CreateReportFormValuesType = Pick<Report, 'objective' | 'targets' | 'vehicleType' | 'frequencies' | 'stream'> & {
  startTime: string;
  ammos: AmmoForm[];
};

const defaultInitialValues = {
  startTime: '',
  vehicleType: '',
  frequencies: '',
  stream: '',
  targets: [
    {
      value: defaultInitialTargetValue,
      description: '',
    }
  ],
  ammos: [initialAmmoValue],
}

const createReportSchema = yup.object().shape({
  startTime: yup.string().required(ErrorMessages.Required).matches(hhmmRexExp, { message: ErrorMessages.HHmm}),
  vehicleType: yup.string().required(ErrorMessages.Required),
  frequencies: yup.string().required(ErrorMessages.Required),
  stream: yup.string(),
  objective: yup.string().required(ErrorMessages.Required),
  targets: yup.array().of(yup.object({
    value: yup.string().required(ErrorMessages.Required).matches(mgrsCoordinatesRegExp, { message: 'Ціль має бути у форматі MGRS' }),
    description: yup.string(),
  })),
  ammos: yup.array().of(yup.object({
    ammoType: yup.string(),
    ammoQuantity: yup.string(),
    detonator: yup.string(),
    detonatorQuantity: yup.string(),
    initiator: yup.string(),
    initiatorQuantity: yup.string(),
  })),
});

interface CreateReportFormProps {
  onSubmit: (report: Report) => void;
}

const CreateReportForm: React.FC<CreateReportFormProps> = ({ onSubmit }) => {

  const { updateTargetMap, config, globalConfig} = useContext(AppContext);

  const [initialValues, setInitialValues] = useState({
    ...defaultInitialValues,
    objective: globalConfig.objectiveOptions?.[0] || '',
    vehicleType: globalConfig.vehicleTypes?.[0] || '',
    frequencies: config?.frequencies || '',
    stream: config?.stream || '',
    targets: [
      {
        value: config.initialTargetValue || defaultInitialTargetValue,
        description: '',
      }
    ],
  });

  const {
    handleSubmit,
    handleChange,
    setFieldValue,
    values,
    touched,
    errors,
  } = useFormik<CreateReportFormValuesType>({
    initialValues,
    validationSchema: createReportSchema,
    onSubmit: (values, { resetForm }) => {
      let startDate;
      try {
        startDate = parse(values.startTime, 'HH:mm', new Date());
        //fix for around midnight filling
        if(!isFuture(startDate)){
          startDate = addDays(startDate, 1)
        }
      }catch(e){
      }

      if(globalConfig.objectives[values.objective].rememberTarget){
        const newTargetMap = convertTargetsToTargetMap(values.targets);
        updateTargetMap(newTargetMap);
      }

      onSubmit({
        ...values,
        id: uuidv4(),
        startTime: startDate || new Date(),
        hitTime: addMinutes(startDate || new Date(), globalConfig.defaultFlightTime || 0),
        ammos: values.ammos.map((ammo) => ({
          ...ammo,
          ammoQuantity: parseInt(ammo.ammoQuantity) || undefined,
          detonatorQuantity: parseInt(ammo.detonatorQuantity) || undefined,
          initiatorQuantity: parseInt(ammo.initiatorQuantity) || undefined,
        })),
        results: [{result: globalConfig.objectives[values.objective].defaultResult, description: ''}],
      });
      resetForm();
    },
    enableReinitialize: true,
  });

  const isAmmoFieldsDisabled = !globalConfig.objectives[values.objective]?.requireAmmo || false;

  const handleChangeStartTime = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value;
    setFieldValue('startTime', val);
    try{
      const startDate = parse(val, 'HH:mm', new Date());
      const endDate = addMinutes(startDate, 2*globalConfig.defaultFlightTime || 0);
      setFieldValue('endTime', format(endDate, 'HH:mm'));
    }catch(e){
    }
  }

  const addTarget = () => {
    setFieldValue('targets', [...values.targets, { value: config.initialTargetValue || defaultInitialTargetValue, description: ''}]);
  }

  const removeTarget = (index: number) => {
    setFieldValue('targets', removeAtIndex(values.targets, index));
  }

  const addAmmo = () => {
    setFieldValue('ammos', [...values.ammos, initialAmmoValue]);
  }

  const removeAmmo = (index: number) => {
    setFieldValue('ammos', removeAtIndex(values.ammos, index))
  }

  const handleChangeObjective = (value: string) => {
    // @ts-ignore
    if(!globalConfig.objectives[value].requireAmmo){
      setFieldValue('ammos', [initialAmmoValue])
    }
    setFieldValue('objective', value);
  };

  useEffect(() => {
    setInitialValues(prevState => ({
      ...prevState,
      objective: Object.keys(globalConfig.objectives)[0],
      vehicleType: globalConfig.vehicleTypes?.[0] || '',
      frequencies: config?.frequencies,
      stream: config?.stream || '',
      targets: [
        {
          value: config.initialTargetValue || defaultInitialTargetValue,
          description: '',
        }
      ],
    }))
  }, [config, globalConfig])

  return <StyledForm onSubmit={handleSubmit}>
    <InputMask
      mask="99:99"
      value={values.startTime}
      onChange={handleChangeStartTime}
    >
      {/*@ts-ignore*/}
      {(inputProps) => <StyledTextField
        {...inputProps}
        autoComplete='off'
        label="Час"
        name="startTime"
        error={touched.startTime && Boolean(errors.startTime)}
        helperText={touched.startTime && errors.startTime}
      />}
    </InputMask>
    <Autocomplete
      disableClearable
      value={values.vehicleType}
      onChange={(e, value) => setFieldValue('vehicleType', value)}
      options={globalConfig.vehicleTypes}
      renderInput={(params) => <StyledTextField
        {...params}
        name="vehicleType"
        label="Тип засобу"
        error={touched.vehicleType && Boolean(errors.vehicleType)}
        helperText={touched.vehicleType && errors.vehicleType}
      />}
    />
    <Autocomplete
      disableClearable
      value={values.frequencies}
      onChange={(e, value) => setFieldValue('frequencies', value)}
      options={globalConfig.frequencies || []}
      renderInput={(params) => <StyledTextField
        {...params}
        name="frequencies"
        label="Частоти"
        error={touched.frequencies && Boolean(errors.frequencies)}
        helperText={touched.frequencies && errors.frequencies}
      />}
    />
    {globalConfig?.shouldHaveStreamField && <StyledTextField
        label="Стрім"
        variant="outlined"
        name="stream"
        value={values.stream}
        onChange={handleChange}
        error={touched.stream && Boolean(errors.stream)}
        helperText={touched.stream && errors.stream}
    />}
    <Autocomplete
      disableClearable
      value={values.objective}
      onChange={(e, value) => handleChangeObjective(value)}
      options={globalConfig.objectiveOptions}
      renderInput={(params) => <StyledTextField
        {...params}
        name="objective"
        label="Мета"
        error={touched.objective && Boolean(errors.objective)}
        helperText={touched.objective && errors.objective}
      />}
    />
    {values.targets.map((target, i) => <TargetInput
        key={i}
        target={target.value}
        targetDescription={target.description || ''}
        setFieldValue={setFieldValue}
        index={i}
        isLast={values.targets.length === i + 1}
        addTarget={addTarget}
        removeTarget={() => removeTarget(i)}
        //@ts-ignore
        error={errors.targets?.[i]?.value}
        touched={touched.targets?.[i]?.value}
        isHumanitarianCargo={globalConfig.objectives[values.objective]?.rememberTarget}
      />)}
    {!isAmmoFieldsDisabled && values.ammos.map((ammo, i) => <AmmoInput
      key={i}
      index={i}
      isLast={values.ammos.length === i + 1}
      addAmmo={addAmmo}
      removeAmmo={() => removeAmmo(i)}
      ammo={ammo}
      setFieldValue={(key: string, value: any) => setFieldValue(`ammos[${i}.${key}`, value)}
      handleChange={handleChange}
      touched={errors?.ammos?.[i]}
      errors={errors.ammos?.[i]}
    />)}
    <Button variant="contained" type="submit">Додати заявку</Button>
  </StyledForm>
};

export default CreateReportForm;

const StyledForm = styled('form')`
  display: flex;
  flex-direction: column;
`;
const StyledTextField = styled(TextField)`
  width: 100%;
  margin-bottom: ${({ theme }) => theme.spacing(4)};
`;
